export const STAGE_ENV_BASE_URL = 'https://d2yzhy3sn9m7nt.cloudfront.net/staging'
export const PROD_ENV_BASE_URL = 'https://d2thw90mo99gar.cloudfront.net/production'

export const stagingConfig = {
    baseURL: STAGE_ENV_BASE_URL,
    listingURL: 'https://api.media-matchers.com/search',
    webContent: 'https://d3lmxxnronq3mg.cloudfront.net/staging/v3/getFrontendCmsWebContent'
}

export const ProdConfig = {
    baseURL: PROD_ENV_BASE_URL,
    listingURL: 'https://api.media-matchers.com/search',
    webContent: 'https://dcqeevex53cg1.cloudfront.net/getFrontendCmsWebContent'
}