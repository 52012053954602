export const mPrograms: any = {
    "1": [{ "text": "General Business", "value": "100", "status": true, }, { "text": "Accounting", "value": "101","status":true, }, { "text": "Business Administration", "value": "102","status":true, }, { "text": "Business Analytics", "value": "103","status":true, }, { "text": "Communications", "value": "104","status":true, }, { "text": "Economics", "value": "105","status":true, }, { "text": "Finance", "value": "106","status":true, }, { "text": "Human Resources", "value": "107","status":true, }, { "text": "Management", "value": "108","status":true, }, { "text": "Digital Marketing", "value": "109","status":true, }, { "text": "Project Management", "value": "110","status":true, }, { "text": "Sports Management", "value": "111","status":true, }, { "text": "Healthcare Administration", "value": "112","status":true, }],

    "2": [{ "text": "General Criminal Justice", "value": "200","status":true, }, { "text": "Corrections", "value": "201","status":true, }, { "text": "Crime Scence Investigation", "value": "202","status":true, }, { "text": "Criminal Justice", "value": "203","status":true, }, { "text": "Criminology", "value": "204","status":true, }, { "text": "Cyber Security", "value": "205","status":true, }, { "text": "Forensic Science", "value": "206","status":true, }, { "text": "Homeland Security", "value": "207","status":true, }, { "text": "Law Enforcement", "value": "208","status":true, }, { "text": "Legal & Paralegal Studies", "value": "209","status":true, }, { "text": "Public Safety Administration", "value": "211","status":true, }, { "text": "Security", "value": "212","status":true, }],

    "3": [{ "text": "General Psychology", "value": "300","status":true, }, { "text": "Addictions & Recovery", "value": "301","status":true, }, { "text": "Applied Psychology", "value": "302","status":true, }, { "text": "Behavioral Psychology", "value": "303","status":true, }, { "text": "Child Psychology", "value": "304","status":true, }, { "text": "Counseling", "value": "305" ,"status":true,}, { "text": "Forensic Psychology", "value": "307" ,"status":true,}, { "text": "Human Services", "value": "308" ,"status":true,}, { "text": "Organizational Psychology", "value": "310" ,"status":true,}, { "text": "Sociology", "value": "311","status":true, }],

    "4": [{ "text": "General Computers & IT", "value": "400","status":true, }, { "text": "Coding Bootcamps", "value": "401","status":true, }, { "text": "Computer Programming", "value": "402" ,"status":true,}, { "text": "Data Science & Analytics", "value": "403" ,"status":true,}, { "text": "Game Art Development", "value": "404","status":true, }, { "text": "Information Systems", "value": "405","status":true, }, { "text": "Information Technology", "value": "406" ,"status":true,}, { "text": "IT Project Managment", "value": "407" ,"status":true,}, { "text": "Network Security", "value": "408" ,"status":true,}, { "text": "Product and Graphic Design", "value": "409" ,"status":true,}, { "text": "Software Engineering", "value": "410" ,"status":true,}, { "text": "Web Development", "value": "411" ,"status":true,}, { "text": "CyberSecurity", "value": "412","status":true, }],

    "5": [{ "text": "Medical Assisting", "value": "500","status":true, }, { "text": "Certified Nursing Assistant (CNA)", "value": "501","status":true, }, { "text": "Allied Health", "value": "502","status":true, }, { "text": "Emergency Management (EMT)", "value": "503" ,"status":true,}, { "text": "Health Science", "value": "505","status":true, }, { "text": "Healthcare Administration", "value": "506","status":true, }, { "text": "Healthcare Management", "value": "507","status":true, }, { "text": "Dental or Pharmacy Technician", "value": "508","status":true, }, { "text": "Medical Billing & Coding", "value": "509","status":true, }, { "text": "Medical Office Assistant", "value": "510","status":true, }, { "text": "Nutition, Fitness, and Sports Science", "value": "511","status":true, }, { "text": "Public Health", "value": "512","status":true, }],

    "6": [{ "text": "General Education", "value": "600","status":true, }, { "text": "Adult Education & Learning", "value": "601","status":true, }, { "text": "Early Childhood Development", "value": "602","status":true, }, { "text": "Educational Administration", "value": "603","status":true, }, { "text": "K-12 Education", "value": "604","status":true, }, { "text": "Social Studies", "value": "605","status":true, }, { "text": "Special Education", "value": "606","status":true, }, { "text": "Teacher Liscensure", "value": "607","status":true, }],

    "7": [{ "text": "General Trade Vocational Career", "value": "700","status":true, }, { "text": "Automotive focused", "value": "701","status":true, }, { "text": "Aviation focused", "value": "702","status":true, }, { "text": "Certified Nursing Assistant (CNA)", "value": "703","status":true, }, { "text": "Construction management", "value": "704","status":true, }, { "text": "Electrical Technican", "value": "705","status":true, }, { "text": "Fire Science", "value": "706","status":true, }, { "text": "Truck Driver (CDL)", "value": "707","status":true, }, { "text": "HVAC", "value": "708","status":true, }, { "text": "Welding", "value": "709","status":true, }],

    "8": [{ "text": "General Liberal Arts", "value": "800","status":true, }, { "text": "Creative Writing & English", "value": "801","status":true, }, { "text": "Communications", "value": "802","status":true, }, { "text": "History", "value": "803" ,"status":true,}, { "text": "Religious Studies", "value": "804","status":true, }, { "text": "Socialogy", "value": "805","status":true, }, { "text": "Humanities", "value": "806","status":true, }, { "text": "Political Science", "value": "807","status":true, }],
    "9": [{ "text": "Undecided", "value": "900","status":true, }],

    "10": [{ "text": "General Nursing", "value": "1000","status":true, }, { "text": "Entry Level", "value": "1001","status":true, }, { "text": "RN Degree Programs", "value": "1002","status":true, }, { "text": "RN to BSN Bridge Programs", "value": "1003","status":true, }, { "text": "MSN Programs", "value": "1004","status":true, }]
}

export const degreeInterestOptions: any = [
    {
        "type": "Career Training",
        "value": "1"
    },
    {
        "type": "Associates Degrees",
        "value": "2"
    },
    {
        "type": "Bachelor's Degrees",
        "value": "3"
    },
    {
        "type": "Master's Degrees",
        "value": "4"
    }
]

export const areaStudyOptions: any = [
    {
        "category_id": "1",
        "category_name": "Business",
        "status": true,
    },
    {
        "category_id": "2",
        "category_name": "Criminal Justice",
        "status": true,
    },
    {
        "category_id": "3",
        "category_name": "Psychology",
        "status": true,
    },
    {
        "category_id": "4",
        "category_name": "Computers & IT",
        "status": true,
    },
    {
        "category_id": "5",
        "category_name": "Healthcare",
        "status": true,
    },
    {
        "category_id": "6",
        "category_name": "Education & Teaching",
        "status": true,
    },
    {
        "category_id": "7",
        "category_name": "Vocational Training",
        "status": true,
    },
    {
        "category_id": "8",
        "category_name": "Liberal Arts",
        "status": true,
    },
    {
        "category_id": "9",
        "category_name": "Undecided",
        "status": true,
    },
    {
        "category_id": "10",
        "category_name": "Nursing",
        "status": true,
    }
]
