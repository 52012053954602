import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Logo from "../assets/images/logo.png";
import { NavLink, useLocation } from "react-router-dom";
import { Container } from "@mui/system";
import CssBaseline from "@mui/material/CssBaseline";
import { WebContext } from "../contextProvider/webContextProvider";

const toolbarStyle = {
    minHeight: "0",
};
const drawerWidth = 300;

const NavBar = (props:any) => {
    const { webContent } = React.useContext(WebContext);
    const { window } = props;
    const location = useLocation()
    const currentLocation = location.pathname
    const [mobileOpen, setMobileOpen] = React.useState(false);


    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="static"
                component="nav"
                sx={{
                    backgroundColor: "#fff",
                    boxShadow: "none",
                    borderBottom: "1px solid #E2E2E2",
                }}
            >
                <Container maxWidth="lg">
                    <Toolbar className="p-0" style={toolbarStyle}>
                        {/* <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ color: "#000", mr: 2, display: { md: "none" } }}
                        >
                            <MenuIcon />
                        </IconButton> */}
                        <React.Fragment>
                            {currentLocation === '/get-information' ?
                                <Box sx={{ flexGrow: 1 }}>
                                    <Box className="lead-frm-logo" mt={1} mb={1}>
                                        <img src={webContent?.defaultData?.footerLogo} alt="header logo" />
                                    </Box>
                                </Box> :
                                <React.Fragment>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Box className="header-logo">
                                            <img src={webContent?.defaultData?.footerLogo} alt="header logo" />
                                        </Box>
                                    </Box>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    </Toolbar>
                </Container>
            </AppBar >
        </Box >
    );
}

export default NavBar;
