import React, { useState, useEffect, useContext } from 'react';
import LeadFormAreaStudy from './LeadFormAreaStudy';
import LeadFormMProgram from './LeadFormMProgram';
import LeadFormDegreeInterest from './LeadFormDegreeInterest';
import LeadFormDuration from './LeadFormDuration';
import LeadFormMilitary from './LeadFormMilitary';
import LeadFormLocationInformation from './LeadFormLocationInformation';
import LeadFormUserInformation from './LeadFormUserInformation';
import { Box, Grid, Skeleton, Toolbar } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import ListingCom from './ListingCom';
import { TLeadFormsValueTypeProps } from '../../types/common';
import LeadFormUserGender from './LeadFormUserGender';
import ThankYou from './ThankYou';
import Faq from '../Faq';
// import NavBar from '../NavBar';
import { WebContext } from '../../contextProvider/webContextProvider';
import baseURLConfig from '../../config/envConfig';
import 'bootstrap/dist/css/bootstrap.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Footer } from '../Footer';
import { getResponse } from '../../Service';
import NavBar from '../NavBar';
import { useTikTokAnalytics } from '../../Service/tikTok-analytics';

const toolbarStyle = {
  minHeight: '1px',
};

declare global {
  interface Window {
    myCallbackFunction: (token: string) => void;
  }
}

export default function FormIndex() {
  const navigate = useNavigate();
  const { webContent, setWebContent } = useContext(WebContext);
  const [mProgramList, setMProgramList] = useState<any>([]);
  const [searchParams] = useSearchParams();
  const queryParams: any = Object.fromEntries(
    searchParams.entries()
  );
  const [isLoading, setIsLoading] = useState<any>(true);
  const [page, setPage] = useState(0);
  const [areaStudyList, setAreaStudyList] = useState<any>([]);
  const [leadFormData, setLeadFormData] = useState<TLeadFormsValueTypeProps>({
    areaStudy: '',
    mProgram: '',
    highestEducationName: '',
    highestEducationYear: '',
    degreeInterest: '',
    military: '',
    militaryAffiliationName: '',
    duration: '',
    userLocation: {
      userAddress: '',
      userCity: '',
      userState: '',
      userCampusType: '',
      userZipCode: '',
    },
    userInformation: {
      userFirstName: '',
      userLastName: '',
      userEmail: '',
      userTimeZone: '',
      userPhoneNumber: '',
      userGender: '',
      // userCheck: false
    },
    lead_education: {
      start_date: '',
      education_level_id: '',
      school_type: '',
      zip: '',
      grad_year: '',
    },
    lead_background: {
      military_type: '',
    },
    lead: {
      phone1: '',
      email: '',
      firstName: '',
      lastName: '',
    },
    lead_address: {
      city: '',
      state: '',
    },
  });

  const [leadIdToken, setLeadIdToken] = useState<string | null>(null);
  const [trustedFormUrl, setTrustedFormUrl] = useState<string | null>(null);
  const totalSteps = 9;

  const getMProgramList = (areaStudy: any) => {
    const mPrograms = webContent?.defaultData?.programContent
      ?.find((val: any) => val?.areaStudy === areaStudy)
      ?.mProgram?.sort((a: any, b: any) => a.label.localeCompare(b.label))
      ?.map((program: any) => ({
        ...program,
        label: program.label === 'General' ? 'Undecided' : program.label,
      }));
    setMProgramList(mPrograms);
    return mPrograms;
  };

  const displayLeadForm = () => {
    switch (page) {
      case 1:
        return (
          <LeadFormAreaStudy
            onNext={handleNextPage}
            leadFormData={leadFormData}
            setLeadFormData={setLeadFormData}
            leadIdToken={leadIdToken}
            trustedFormUrl={trustedFormUrl}
            areaStudyData={areaStudyList}
            mProgramList={mProgramList}
            getMProgramList={getMProgramList}
          />
        );

      case 2:
        return (
          <LeadFormMProgram
            onNext={handleNextPage}
            leadFormData={leadFormData}
            setLeadFormData={setLeadFormData}
            leadIdToken={leadIdToken}
            trustedFormUrl={trustedFormUrl}
          />
        );

      case 3:
        return (
          <LeadFormDegreeInterest
            onNext={handleNextPage}
            leadFormData={leadFormData}
            setLeadFormData={setLeadFormData}
            leadIdToken={leadIdToken}
            trustedFormUrl={trustedFormUrl}
          />
        );

      case 4:
        return (
          <LeadFormMilitary
            onNext={handleNextPage}
            leadFormData={leadFormData}
            setLeadFormData={setLeadFormData}
            leadIdToken={leadIdToken}
            trustedFormUrl={trustedFormUrl}
          />
        );

      case 5:
        return (
          <LeadFormDuration
            onNext={handleNextPage}
            leadFormData={leadFormData}
            setLeadFormData={setLeadFormData}
            leadIdToken={leadIdToken}
            trustedFormUrl={trustedFormUrl}
          />
        );

      case 6:
        return (
          <LeadFormUserGender
            onNext={handleNextPage}
            leadFormData={leadFormData}
            setLeadFormData={setLeadFormData}
            leadIdToken={leadIdToken}
            trustedFormUrl={trustedFormUrl}
          />
        );

      case 7:
        return (
          <LeadFormLocationInformation
            onNext={handleNextPage}
            leadFormData={leadFormData}
            setLeadFormData={setLeadFormData}
            leadIdToken={leadIdToken}
            trustedFormUrl={trustedFormUrl}
          />
        );

      case 8:
        return (
          <LeadFormUserInformation
            onNext={handleNextPage}
            leadFormData={leadFormData}
            setLeadFormData={setLeadFormData}
            leadIdToken={leadIdToken}
            trustedFormUrl={trustedFormUrl}
            queryParams={queryParams}
          />
        );

      case 9:
        return (
          <ListingCom
            onNext={handleNextPage}
            leadFormData={leadFormData}
            leadIdToken={leadIdToken}
            trustedFormUrl={trustedFormUrl}
            queryParams={queryParams}
          />
        );

      case 10:
        return <ThankYou leadFormData={leadFormData} />;
      // default:
      //   return <ThankYou leadFormData={leadFormData} />;
    }
  };
  console.log('leadIdToken', leadIdToken);
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'LeadiDscript_campaign';
    script.type = 'text/javascript';
    script.async = true;
    script.src =
      '//create.lidstatic.com/campaign/d50a3557-9854-7b8f-792c-527750401f5f.js?snippet_version=2&f=reset&callback=myCallbackFunction';

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // Callback function to handle the token
  window.myCallbackFunction = function (token) {
    setLeadIdToken(token);
  };

  const getWebSiteData = async (data?: any) => {
    try {
      const contentResponse = await getResponse(
        `${baseURLConfig.webContent}?websiteDomainName=compareonlinecriminologydegrees${
          data ? data : ''
        }`
      );
      if (contentResponse?.data?.defaultData) {
        setWebContent(contentResponse?.data);
        if(!contentResponse?.data?.defaultData?.isGetInformationAccessible){
          navigate('/');
        }
      }
      return contentResponse?.data;
    } catch (error: any) {
      // setError(error);
    }
  };

  const getAreaStudy = () => {
    const areaStudy =
      webContent?.defaultData?.programContent
        ?.map(({ areaStudy, label }: any) =>
          areaStudy == '9'
            ? { areaStudy, label: 'Undecided' }
            : { areaStudy, label }
        )
        ?.sort((a: any, b: any) => a.label.localeCompare(b.label)) || [];
    setAreaStudyList(areaStudy);
    return areaStudy;
  };

  const findProgramById = (id: number) => {
    return (
      webContent?.defaultData?.programContent
        ?.flatMap((area: any) => area.mProgram)
        .find((program: any) => program.id === id) || null
    );
  };

  useEffect(() => {
    if (webContent) {
      const { degreeInterest, mProgram, areaStudy } = queryParams;
      const getAreaStudyList = getAreaStudy();
      if (webContent?.defaultData?.template == 2) {
        if (
          getAreaStudyList.some(
            (study: any) =>
              study.areaStudy === webContent?.defaultData?.areaStudy
          )
        ) {
          const areaStudyData = webContent?.defaultData?.areaStudy;
          const mProgramData = getMProgramList(areaStudyData)?.find((val:any) => val?.id === mProgram)?.id;
          
          const shouldNavigate = Boolean(areaStudy && areaStudy !== areaStudyData) || Boolean(mProgram && mProgram !== mProgramData);
          if (shouldNavigate) {
            navigate('/get-information');
          }
          setLeadFormData({
            ...leadFormData,
            areaStudy: areaStudyData,
            mProgram: shouldNavigate ? '' : mProgramData || '',
          });
          setPage(1);
        }
      } else {
        if (areaStudy || mProgram) {
          if (areaStudy && mProgram) {
            if (
              getAreaStudyList?.some(
                (study: any) => study.areaStudy === areaStudy
              )
            ) {
              const mProgramList = getMProgramList(areaStudy);
              setLeadFormData({ ...leadFormData, areaStudy: areaStudy });
              if (
                mProgramList?.some((program: any) => program?.id === mProgram)
              ) {
                setPage(2);
                setLeadFormData({
                  ...leadFormData,
                  mProgram: mProgram,
                  areaStudy: areaStudy,
                });
              } else {
                setPage(1);
              }
            } else {
              setPage(1);
            }
          } else {
            if (areaStudy) {
              setPage(1);
              if (
                getAreaStudyList.some(
                  (study: any) => study.areaStudy === areaStudy
                )
              ) {
                getMProgramList(areaStudy);
                setLeadFormData({ ...leadFormData, areaStudy: areaStudy });
              }
            }
            if (mProgram) {
              const getValues = findProgramById(mProgram);
              if (getValues) {
                setPage(2);
                setLeadFormData({
                  ...leadFormData,
                  areaStudy: getValues?.areaStudy,
                  mProgram: getValues?.id,
                });
              } else {
                setPage(1);
              }
            }
          }
        } else {
          setPage(1);
        }
      }
      setIsLoading(false);
    }
  }, [webContent]);

  useTikTokAnalytics('COJ9P3RC77UAA32C53LG');

  useEffect(() => {
    // if (!(areaStudy || mProgram)) {
    //   setPage(1);
    // } else {
    //   setIsLoading(true);
    // }
    if (!webContent) {
      getWebSiteData();
    }
    const field: string = 'xxTrustedFormCertUrl';
    const provideReferrer: boolean = false;

    // Create the script element
    const tf: HTMLScriptElement = document.createElement('script');
    tf.type = 'text/javascript';
    tf.async = true;
    tf.src =
      'http' +
      ('https:' === document.location.protocol ? 's' : '') +
      '://api.trustedform.com/trustedform.js?provide_referrer=' +
      encodeURIComponent(provideReferrer.toString()) +
      '&field=' +
      encodeURIComponent(field) +
      '&l=' +
      new Date().getTime() +
      Math.random();

    // Append the script to the DOM
    const s: HTMLScriptElement | null =
      document.getElementsByTagName('script')[0];
    if (s && s.parentNode) {
      s.parentNode.insertBefore(tf, s);
    }

    // Set the URL to state when the script is loaded
    tf.onload = () => {
      setTrustedFormUrl(tf.src);
    };
  }, []);

  const handleNextPage = () => {
    setPage(nextPage => nextPage + 1);
  };

  const progressBarStyle = {
    '--custom-progress-bar-color': webContent?.defaultData?.primaryColor,
  } as React.CSSProperties;
  
  return isLoading ? (
    <Box>
      <Skeleton
        sx={{ height: 80, marginBottom: 1 }}
        animation="wave"
        variant="rectangular"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: { xs: '90%', sm: '60%', md: '60%' },
          }}
        >
          <Skeleton
            animation="wave"
            variant="text"
            height={25}
            sx={{ marginTop: 6 }}
            width="100%"
          />
        </Box>
        <Box
          sx={{
            width: { xs: '90%', sm: '50%', md: '50%' },
          }}
        >
          <Skeleton
            animation="wave"
            variant="text"
            height={80}
            sx={{ marginTop: 2 }}
            width="100%"
          />
        </Box>

        <Box
          sx={{
            width: { xs: '90%', sm: '30%', md: '30%' },
            marginBottom: { xs: '20%', sm: '10%', md: '10%' },
          }}
        >
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={40}
            sx={{ marginTop: 3 }}
            width="100%"
          />
        </Box>
      </Box>
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{
            // marginTop: { xs: '10%', sm: '3%', md: '3%' },
            height: { xs: '40%', sm: '30%', md: '30%' },
            position: 'fixed',
            left: 0,
            bottom: 0,
          }}
          width="100%"
        />

    </Box>
  ) : (
    <>
      <NavBar />
      <Box
        maxWidth={page < 9 ? '600px' : '100%'}
        mx="auto"
        py={[4, 6, 8]}
        flexGrow="1"
        width="100%"
      >
        {page < 9 && (
          // <LinearProgress
          //   variant="determinate"
          //   value={((page + 1) / totalSteps) * 100}
          //   color="success"
          // />
          <Box
              sx={{
                padding: { xs: '0 10px', sm: '0 10px', md: '0' },
              }}
              className="custom-progress-bar"
            >
              <ProgressBar
                color="red"
                animated
                now={Math.round((page / totalSteps) * 100)}
                label={`${Math.round((page / totalSteps) * 100)}%`}
                style={progressBarStyle}
              />
            </Box>
        )}
        <Box m={page < 9 ? 3 : ''}>
          <input type="hidden" name="leadid_token" value={leadIdToken || ''} />
          <div id="LeadiDscript"></div>
          <input
            type="hidden"
            name="trustedid_token"
            value={trustedFormUrl || ''}
          />
          <div id="LeadiDscript"></div>
          {displayLeadForm()}
        </Box>
      </Box>
      <Box mt={[12, 0]}>
        <Footer />
      </Box>
    </>
  );
}
