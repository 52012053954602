import { Box, Container, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Logo from '../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { WebContext } from '../contextProvider/webContextProvider';

export const Footer = () => {
  const { webContent } = useContext(WebContext);
  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  // }, []);

  return (
    <Box
      className="footer"
      sx={{ padding: !webContent?.defaultData?.footerContentToggle ? '0' : '' }}
    >
      {webContent?.defaultData?.footerContentToggle && (
        <Container>
          <Grid container columnSpacing={4}>
            <Grid item xs={12} md={3}>
              <Box display="flex" alignItems="center" height="100%">
                {/* <img src={Logo} alt="" width="180" /> */}
                <img
                  src={webContent?.defaultData?.footerLogo}
                  alt="Footer Logo"
                  width="180"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography
                component="p"
                color="initial"
                mt={3}
                fontSize="14px"
                dangerouslySetInnerHTML={{
                  __html: webContent?.defaultData?.footerContent,
                }}
              ></Typography>
            </Grid>
          </Grid>
        </Container>
      )}
      {webContent?.defaultData?.footerLinksToggle && (
      <Box
        className="footer-links"
        sx={{
          marginTop: !webContent?.defaultData?.footerContentToggle
            ? '0px'
            : '40px',
        }}
      >

          <Container>
            {webContent?.defaultData?.footerLinks?.map((val: any) => (
              <Link to={val?.value} target="_blank">
                {val?.key}
              </Link>
            ))}
          </Container>
      
      </Box>  )}
      <Box className="copyright">
        Copyright © 2023 Media Matchers LLC. All Rights Reserved.
      </Box>
    </Box>
  );
};
