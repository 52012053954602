import FormIndex from "../components/LeadFormSteps/FormIndex";
import CriminalJusticeListing from "../Container";

export const routePath = [
    {
        name: "Home",
        path: "/",
        exact: true,
        component: CriminalJusticeListing
    },
    {
        name: "General Criminal Justice",
        path: "/criminaljustice/generalcriminaljustice",
        exact: true,
        component: CriminalJusticeListing
    },
    {
        name: "Corrections",
        path: "/criminaljustice/corrections",
        exact: true,
        component: CriminalJusticeListing
    },
    {
        name: "Crime Scence Investigation",
        path: "/criminaljustice/crimescenceinvestigation",
        exact: true,
        component: CriminalJusticeListing
    },
    {
        name: "Criminal Justice",
        path: "/criminaljustice/criminaljustice",
        exact: true,
        component: CriminalJusticeListing
    },
    {
        name: "Criminology",
        path: "/criminaljustice/criminology",
        exact: true,
        component: CriminalJusticeListing
    },
    {
        name: "Cyber Security",
        path: "/criminaljustice/cybersecurity",
        exact: true,
        component: CriminalJusticeListing
    },
    {
        name: "Forensic Science",
        path: "/criminaljustice/forensicscience",
        exact: true,
        component: CriminalJusticeListing
    },
    {
        name: "Homeland Security",
        path: "/criminaljustice/homelandsecurity",
        exact: true,
        component: CriminalJusticeListing
    },
    {
        name: "Law Enforcement",
        path: "/criminaljustice/lawenforcement",
        exact: true,
        component: CriminalJusticeListing
    },
    {
        name: "Legal & Paralegalstudies",
        path: "/criminaljustice/legal&paralegalstudies",
        exact: true,
        component: CriminalJusticeListing
    },
    {
        name: "Public Safety Administration",
        path: "/criminaljustice/publicsafetyadministration",
        exact: true,
        component: CriminalJusticeListing
    },
    {
        name: "Security",
        path: "/criminaljustice/security",
        exact: true,
        component: CriminalJusticeListing
    },
    {
        name: "LeadForm",
        path: "/get-information",
        exact: true,
        component: FormIndex
    },
    {
        name: "Page Not Found",
        path: "*",
        exact: true,
        component: CriminalJusticeListing
    }
]
