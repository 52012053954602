import Healthcare from '../../assets/images/LeadFormIcon/areastudy/healthcare.svg'
import Business from '../../assets/images/LeadFormIcon/areastudy/business.svg'
import Education from '../../assets/images/LeadFormIcon/areastudy/education.svg'
import Trades from '../../assets/images/LeadFormIcon/areastudy/trades.svg'
import Justice from '../../assets/images/LeadFormIcon/areastudy/justice.svg'
import Arts from '../../assets/images/LeadFormIcon/areastudy/arts.svg'
import IT from '../../assets/images/LeadFormIcon/areastudy/IT.svg'
import publicAffair from '../../assets/images/LeadFormIcon/areastudy/public affair.svg'
import Liberal from '../../assets/images/LeadFormIcon/areastudy/liberal.svg'
import HighSchool from '../../assets/images/LeadFormIcon/degree/highschool.svg';
import GED from '../../assets/images/LeadFormIcon/degree/highschool.svg';
import Associate from '../../assets/images/LeadFormIcon/degree/associate.svg';
import Bachelors from '../../assets/images/LeadFormIcon/degree/bachelors.svg';
import Masters from '../../assets/images/LeadFormIcon/degree/masters.svg';
import Doctorate from '../../assets/images/LeadFormIcon/degree/doctorate.svg';
import Possibility from '../../assets/images/LeadFormIcon/duration/possible.svg'
import Countdown from '../../assets/images/LeadFormIcon/duration/threemonth.svg'
import Watch from '../../assets/images/LeadFormIcon/duration/watch.svg'
import Note from '../../assets/images/LeadFormIcon/duration/note.svg'
import Calender from '../../assets/images/LeadFormIcon/duration/calender.svg'
import NotSure from '../../assets/images/LeadFormIcon/duration/notsure.svg'
import Yes from '../../assets/images/LeadFormIcon/military/yes.svg'
import No from '../../assets/images/LeadFormIcon/military/no.svg'
import {  AreaOptionProps, CampusTypeProps, HighestEducationProps, MilitaryAffiliationProps, StartTimeFrameProps } from '../../types/leadOptions'

// Lead Forms Titles
export const LeadFormAreaStudyTitle = "Select an area of Study and Program to Begin"
export const LeadFormMProgramTitle = "Great! See Below for Available Online {} Options"
export const LeadFormHighestEducationTitle = "What's The Highest Level of Education You've Completed?"
export const LeadFormHighestEducationYear = "What is Your High School Grad Year?"
export const LeadFormMilitaryAffiliationTitle = "Are You Affiliated with the Military?"
export const LeadFormUserGenderAffiliationTitle = "Choose Your Gender"
export const LeadFormMilitaryAffiliationName = "Excellent! How are you affiliated?"
export const LeadFormStartTimeFrameTitle = "When Would You Like to Start Classes?"
export const LeadFormUserLocationTitle = "Almost Done! Enter Your Location to See Available Options in Your Area"
export const LeadFormUserInfoTitle = "Last Step! Enter Your Information Below to See Matches"
export const LeadFormUserCheckTitle1 = "By Checking this box and providing my contact information,I am giving"
export const LeadFormUserCheckTitle2 = "consent to contact me by email or telephone,including by text message regarding educational services.I acknowledge these calls may be pre-recorded messages and/or generated using an automated dialing system.I understand that I am under no obligation to provide consent to purchase any goods and services.Msg and data rates may apply."
export const LeadFormResultTitle = "Way to take A Step to Further Your Education, {}! A College Representative Will Reach Out To You Shortly Will More Information"
export const LeadFormMatchTitle = "Compare Additional Online College Matches Below."


/* Lead Forms Icons */

// Lead Form Area Study Icon
export const LeadFormAreaIcons: AreaOptionProps[] = [{ name: 'Healthcare', src: Healthcare }, { name: 'Business', src: Business }, { name: 'Education & Teaching', src: Education }, { name: 'Vocational Training', src: Trades }, { name: 'Criminal Justice', src: Justice }, { name: 'General', src: Arts }, { name: 'Computers & IT', src: IT }, { name: 'General', src: publicAffair }, { name: 'Liberal Arts', src: Liberal }, { name: 'Nursing', src: Healthcare }, { name: 'Psychology', src: publicAffair }]

// Lead Form Hightest Education Icon
export const degreeData = [{ name: 'Certificate', src: HighSchool }, { name: 'Associates', src: Associate }, { name: 'Bachelors', src: Bachelors }, { name: 'Masters', src: Masters }, { name: 'Graduate Programs(Certifications, Master & Doctorate)', src: Doctorate }, { name: 'Unknown', src: GED },]

export const LeadFormHightestEducation: HighestEducationProps[] = [{ name: 'High School Diploma', src: HighSchool, tcpaValue: '1', additionalValue: 'education-level-high-school-diploma' }, { name: 'Associates Completed', src: Associate, tcpaValue: '6', additionalValue: 'education-level-associates-degree' }, { name: 'Bachelors Completed', src: Bachelors, tcpaValue: '7', additionalValue: 'education-level-bachelors-degree' }, { name: 'Grad Program Completed', src: Doctorate, tcpaValue: '8', additionalValue: 'education-level-ged' }, { name: 'No High School Diploma', src: Masters, tcpaValue: '2', additionalValue: 'education-level-no-high-school-diploma' }, { name: 'GED Completed', src: GED, tcpaValue: '3', additionalValue: 'education-level-ged' }]

// Lead Form StartTimeFrame Icon
export const LeadFormStartTimeFrame: StartTimeFrameProps[] = [{ name: 'immediately', src: Possibility, tcpaValue: '1', additionalValue: 'desired-start-date-immediately' }, { name: '1-3 Months', src: Countdown, tcpaValue: '2', additionalValue: 'desired-start-date-1-3-months' }, { name: '4-6 Months', src: Watch, tcpaValue: '3', additionalValue: 'desired-start-date-4-6-months' }, { name: '7-12 Months', src: Note, tcpaValue: '4', additionalValue: 'desired-start-date-7-12-months' }, { name: 'Later than 1 year', src: Calender, tcpaValue: '5', additionalValue: 'desired-start-date-more-than-1-yeardesired-start-date-not-sure' }, { name: 'Unknown', src: NotSure, tcpaValue: '', additionalValue: 'military-no-military-affiliation' }]

// Lead Form Military Icon
export const LeadFormMilitaryConfirmation = [{ name: 'Yes', src: Yes }, { name: 'No', src: No }]

// Lead Form User Gender
export const LeadFormUserGenderConfirmation = [{ name: 'Male', src: Yes, value: 'M' }, { name: 'Female', src: No, value: 'F' }]

// Lead Form Military Options
export const LeadFormMilitaryOptions: MilitaryAffiliationProps[] = [{ name: 'Active Duty', tcpaValue: '2', additionalValue: 'military-af-active-duty-ad' }, { name: 'Veteran', tcpaValue: '3', additionalValue: 'military-af-veteran' }, { name: 'Guard', tcpaValue: '4', additionalValue: 'military-ar-selective-reserve-sr' }, { name: 'Spouse', tcpaValue: '5', additionalValue: 'military-af-spouse-of-ad-or-sr' }, { name: 'Dependent', tcpaValue: '6', additionalValue: 'military-no-military-affiliation' }]

export const LeadFormCampusTypeOptions: CampusTypeProps[] = [{ name: 'Either (Online/Campus)', tcpaValue: '1', additionalValue: '3' }, { name: '100% Online', tcpaValue: '2', additionalValue: '1' }, { name: 'Campus Based', tcpaValue: '3', additionalValue: '2' },]

export const LeadFormHightEducationOptions = [{ value: '2024' },
{ value: '2023' },
{ value: '2022' },
{ value: '2021' },
{ value: '2020' },
{ value: '2019' },
{ value: '2018' },
{ value: '2017' },
{ value: '2016' },
{ value: '2015' },
{ value: '2014' },
{ value: '2013' },
{ value: '2012' },
{ value: '2011' },
{ value: '2010' },
{ value: '2009' },
{ value: '2008' },
{ value: '2007' },
{ value: '2006' },
{ value: '2005' },
{ value: '2004' },
{ value: '2003' },
{ value: '2002' },
{ value: '2001' },
{ value: '2000' },
{ value: '1999' },
{ value: '1998' },
{ value: '1997' },
{ value: '1996' },
{ value: '1995' },
{ value: '1994' },
{ value: '1993' },
{ value: '1992' },
{ value: '1991' },
{ value: '1990' },
{ value: '1989' },
{ value: '1988' },
{ value: '1987' },
{ value: '1986' },
{ value: '1985' },
{ value: '1984' },
{ value: '1983' },
{ value: '1982' },
{ value: '1981' },
{ value: '1980' },
{ value: '1979' },
{ value: '1978' },
{ value: '1977' },
{ value: '1976' },
{ value: '1975' },
{ value: '1974' },
{ value: '1973' },
{ value: '1972' },
{ value: '1971' },
{ value: '1970' },
{ value: '1969' },
{ value: '1968' },
{ value: '1967' },
{ value: '1966' },
{ value: '1965' },
{ value: '1964' },
{ value: '1963' },
{ value: '1962' },
{ value: '1961' },
{ value: '1960' },
{ value: '1959' },
{ value: '1958' },
{ value: '1957' },
{ value: '1956' },
{ value: '1955' },
{ value: '1954' },
{ value: '1953' },
{ value: '1952' },
{ value: '1951' },
{ value: '1950' }
]
