import * as React from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  CssBaseline,
  Container,
  Tooltip,
} from '@mui/material';
import Logo from '../assets/images/logo.png';
import { useLocation } from 'react-router-dom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { WebContext } from '../contextProvider/webContextProvider';

const toolbarStyle = {
  minHeight: '0',
};

function Header() {
  const { webContent, setWebContent } = React.useContext(WebContext);
  const location = useLocation();
  const currentLocation = location.pathname;

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="static"
        component="nav"
        sx={{
          backgroundColor: '#fff',
          boxShadow: 'none',
          borderBottom: '1px solid #E2E2E2',
        }}
      >
        <Box className="top-header">
          <Box className="top-header-content">
            <Box className="top-header-left">
              {webContent?.defaultData?.advertiserMessage} -
              <Box className="advertiser-tooltip mobile" component="span">
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    className="top-header-tooltip-content"
                    title={webContent?.defaultData?.advertiserDisclosure}
                  >
                    <Box onClick={handleTooltipOpen} className="top-header-btn">
                      Advertiser Disclosure
                    </Box>
                  </Tooltip>
                </ClickAwayListener>
              </Box>
              <Box
                className="advertiser-tooltip desktop"
                sx={{ cursor: 'pointer' }}
              >
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  className="top-header-tooltip-content"
                  title={<div
                    dangerouslySetInnerHTML={{__html: webContent?.defaultData?.advertiserDisclosure}}
                  />}
                >
                  <Box className="top-header-btn">Advertiser Disclosure</Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <Container maxWidth="lg">
          <Toolbar className="p-0" style={toolbarStyle}>
            <React.Fragment>
              {currentLocation === '/get-information' ? <Box sx={{ flexGrow: 1 }}>
                <Box className="lead-frm-logo">
                  <img src={Logo} alt="header logo" />
                </Box>
              </Box> : ''
              }
            </React.Fragment>
          </Toolbar>
        </Container> */}
      </AppBar>
    </Box>
  );
}

export default Header;
