import { Box, Card, CardContent, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { LeadFormStartTimeFrame, LeadFormStartTimeFrameTitle } from '../../constants/leadForms';
import { cardProperty } from '../../constants/styles';
import { WebContext } from '../../contextProvider/webContextProvider';

const LeadFormDuration = ({ onNext, leadFormData, setLeadFormData }: any) => {
  const { webContent } = React.useContext(WebContext);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const handleSubmit = (durationTime: string) => {
    setLeadFormData({ ...leadFormData, duration: durationTime })
    onNext();
  }

  return (
    <Box>
      <Box textAlign='center'>
        <Typography component="h5" fontSize="30px" maxWidth="600px" lineHeight="1.3" mx="auto" fontWeight="800" mb={2}>{LeadFormStartTimeFrameTitle}</Typography>
      </Box>
      <Box display="flex" justifyContent="center" flexWrap="wrap"  mt={[4,0]} gap="30px" maxWidth="510px" mx="auto">
        {LeadFormStartTimeFrame.map((duration:any, index:any) =>
          <Card key={index} onClick={() => handleSubmit(duration as any)} className='frm-card' sx={{
            '&:hover': {
              outline: `2px solid ${webContent?.defaultData?.primaryColor}`,
            },
            ...cardProperty
          }}>
            <img src={duration.src} alt='icons' />
            <CardContent sx={{ p: "0 !important", textAlign: "center" }}>
              <Typography fontWeight="600" lineHeight="1.3" fontSize="14px" px={2} sx={{ height: "48px" }}>
                {duration.name} </Typography>
            </CardContent>
          </Card>
        )}
      </Box>
    </Box >
  )
}

export default LeadFormDuration