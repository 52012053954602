import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { SEO_API } from '../common/url';
import { getSEOConfigResponse } from '../Service';
import { WebContext } from '../contextProvider/webContextProvider';

const SeoHandler = () => {
  const { webContent, setWebContent } = useContext(WebContext);
  // State for seoData
  const [seoData, setSeoData] = useState<any>({});
  // Get base URL
  const baseUrl = window.location.origin;

  // useEffect(() => {
  //   getSEOConfig()
  // }, [])


  const getSEOConfig = async () => {
    try {
      const response = await getSEOConfigResponse(`${SEO_API}?id=4b21ef1f-5223-49ab-a1d5-91ca4ca42445`);
      console.log('response', response);
      setSeoData(response)
    }
    catch (error) {
      console.log('response', error);

    }
  }


  return (
    <Helmet>
      {/* Charset */}
      <link rel="icon" type="image/png" href={webContent?.defaultData?.favIcon} />
      <meta charSet="utf-8" />

      <meta name="author" content="Compare Online Degrees" />

      {/* Title Tag */}
      {/* {seoData?.seoTitle && <title>{seoData.seoTitle}</title>} */}
      {<title>{webContent?.seoData?.seoTitle}</title>}

      {/* Keywords */}
      {/* {seoData?.seoKeywords && (
        <meta name="keywords" content={seoData.seoKeywords}></meta> */}
         {webContent?.seoData?.seoKeywords && (
        <meta name="keywords" content={webContent?.seoData?.seoKeywords}></meta>
      )}

      {/* Meta Description */}
      {/* {seoData.seoDescription && (
        <meta name="description" content={seoData.seoDescription}></meta> */}
        {webContent?.seoData.seoDescription && (
        <meta name="description" content={webContent?.seoData.seoDescription}></meta>
      )}

      {/* Canonical URL */}
      {/* <link rel="canonical" href={seoData?.seoCanonicalUrl} /> */}
      <link rel="canonical" href={webContent?.seoData?.seoCanonicalUrl} />

      {/* Robots Meta Tag (controls search engine behavior) */}
      <meta name="robots" content="index" />

      {/* Open Graph (for Social Media Sharing) */}

      {/* {seoData?.ogType && (
      <meta property="og:type" content={seoData?.ogType} /> */}
      {webContent?.seoData?.ogType && (
      <meta property="og:type" content={webContent?.seoData?.ogType} />
      )}

      {/* {seoData?.ogLocale && (
            <meta property="og:locale" content={seoData?.ogLocale} /> */}
            {webContent?.seoData?.ogLocale && (
            <meta property="og:locale" content={webContent?.seoData?.ogLocale} />
      )}
      
      <meta property="og:site_name" content="Compare Online Degree" />

      {/* {seoData?.seoTitle && (
        <meta property="og:title" content={seoData.seoTitle} /> */}
         {webContent?.seoData?.seoTitle && (
        <meta property="og:title" content={webContent?.seoData.seoTitle} />
      )}

      {/* {seoData?.seoDescription && (
        <meta property="og:description" content={seoData.seoDescription} /> */}
        {webContent?.seoData?.seoDescription && (
        <meta property="og:description" content={webContent?.seoData.seoDescription} />
      )}

      {/* {seoData?.seoImage && (
        <meta property="og:image" content={seoData.seoImage} /> */}
        {webContent?.seoData?.seoImage && (
        <meta property="og:image" content={webContent?.seoData.seoImage} />
      )}

      {/* <meta property="og:url" content={seoData?.seoCanonicalUrl || baseUrl} /> */}
      <meta property="og:url" content={webContent?.seoData?.seoCanonicalUrl || baseUrl} />

      {/* Twitter Card (for Twitter Sharing) */}
      {/* {seoData?.twitterCard && (
        <meta name="twitter:card" content={seoData?.twitterCard} /> */}
        {webContent?.seoData?.twitterCard && (
        <meta name="twitter:card" content={webContent?.seoData?.twitterCard} />
      )}

      {/* {seoData?.twitterTitle && (
        <meta name="twitter:title" content={seoData?.twitterTitle} /> */}
        {webContent?.seoData?.twitterTitle && (
        <meta name="twitter:title" content={webContent?.seoData?.twitterTitle} />
      )}

      {/* {seoData?.twitterDescription && ( */}
      {webContent?.seoData?.twitterDescription && (
        <meta
          name="twitter:description"
          // content={seoData?.twitterDescription}
          content={webContent?.seoData?.twitterDescription}
        />
      )}
      {/* {seoData?.twitterSite && (
        <meta property="twitter:site" content={seoData?.twitterSite} /> */}
         {webContent?.seoData?.twitterSite && (
        <meta property="twitter:site" content={webContent?.seoData?.twitterSite} />
      )}
      {/* {seoData?.twitterImage && (
        <meta name="twitter:image" content={seoData?.twitterImage} /> */}
         {webContent?.seoData?.twitterImage && (
        <meta name="twitter:image" content={webContent?.seoData?.twitterImage} />
      )}

      {/* Hreflang Tags */}
      <link rel="alternate" href={baseUrl} hrefLang="en" />
    </Helmet>
  );
};

export default SeoHandler;
