import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Card, CardContent, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { cardProperty } from '../../constants/styles';
import { LeadFormHighestEducationTitle, LeadFormHighestEducationYear, LeadFormHightEducationOptions, LeadFormHightestEducation } from '../../constants/leadForms';
import { WebContext } from '../../contextProvider/webContextProvider';

const LeadFormDegreeInterest = ({ onNext, leadFormData, setLeadFormData }: any) => {
  const { webContent } = React.useContext(WebContext);
  const [selectedDegreeClick, setSelectedDegreeClick] = useState<Boolean>(false);
  const [isDegreeChange, setIsDegreeChange] = useState('')
  const [selectedCard, setSelectedCard] = useState(null);
  const gradYearnRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);


  const handleSubmit = (degreeName: any) => {
    if (selectedCard !== degreeName) {
      setSelectedCard(degreeName);
      setSelectedDegreeClick(true);
    }
    setLeadFormData({ ...leadFormData, highestEducationName: degreeName });
    // window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    if (gradYearnRef.current) {
    gradYearnRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleChangeYear = (event: any) => {
    const selectedYear = event.target.value as string;
    setLeadFormData({ ...leadFormData, highestEducationYear: selectedYear });
    setIsDegreeChange(selectedYear)
    onNext();
  };

  return (
    <Box>
      <Box textAlign="center">
        <Typography component="h5" fontSize="30px" maxWidth="600px" lineHeight="1.3" mx="auto" fontWeight="800" mb={2}>{LeadFormHighestEducationTitle}</Typography>
      </Box>
      <Box display='flex' flexWrap='wrap' justifyContent='center' gap="30px" maxWidth="510px" mx="auto" mt={[4,0]}>
        {LeadFormHightestEducation.map((degree:any, index:any) => {
          return (
            <Card sx={{
              '&:hover': {
                outline: `2px solid ${webContent?.defaultData?.primaryColor}`,
              },
              ...cardProperty
            }} key={index}
              onClick={() => handleSubmit(degree)}
              // className={selectedDegree === degree.name ? 'frm-card selected' : 'frm-card'}
              className={`frm-card ${selectedCard === degree ? 'selected' : ''}`}
            >
              <img src={degree.src} alt={degree.name} />
              <CardContent sx={{ p: "0 !important" }}>
                <Typography fontWeight="600" lineHeight="1.3" fontSize="14px" px={4} sx={{ height: "48px", textAlign: "center" }}>
                  {degree.name}
                </Typography>
              </CardContent>
            </Card>
          )
        })}
      </Box>
      <Box ref={gradYearnRef} mt={3}>
      {selectedDegreeClick && (<>
        <Box textAlign="center">
          <Typography component="h6" fontSize="22px" maxWidth="600px" lineHeight="1.3" mx="auto" fontWeight="800">{LeadFormHighestEducationYear}</Typography>
        </Box>
        <FormControl sx={{ width: "300px", display: "flex", m: "18px auto 0" }}>
          <InputLabel>Highest Education</InputLabel>
          <Select sx={{ backgroundColor: '#ffffff !important' }}
            label="Highest Education"
            // value={leadFormData.leadDegreeInterestYear}
            value={isDegreeChange}
            onChange={handleChangeYear}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
          >
            {LeadFormHightEducationOptions.map((year:any, index:any) => (
              <MenuItem value={year as any} key={index}>{year.value}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </>)}</Box>
    </Box>
  )
}

export default LeadFormDegreeInterest