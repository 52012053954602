import React, { useContext } from "react";
import { Box, Container,Accordion,AccordionDetails,AccordionSummary,Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation } from "react-router-dom";
import { questionAnswerFAQ } from "../MockData/faq";
import { WebContext } from "../contextProvider/webContextProvider";

function Faq() {
  const { webContent, setWebContent } = useContext(WebContext);
  const location = useLocation()
  const currentLocation = location.pathname
  const [expanded, setExpanded] = React.useState<boolean | string>(false);
  const handleChange = (panel: any) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <React.Fragment>
      {currentLocation === '/get-information' ? " " :
        <Box className="faq" sx={{ backgroundColor:  webContent?.defaultData?.secondaryColor}} py={5}>
          <Container>
            <Typography component="h2" fontWeight="800" color="#fff" fontSize="35px" mb={4}>Frequently Asked Questions</Typography>
            {/* {questionAnswerFAQ.map((quesAns, index) => (
              <Accordion key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <Typography fontSize="20px" fontWeight="600">{quesAns.question} </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography> {quesAns.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))} */}
               { webContent?.faqData?.faqs?.map((quesAns:any, index:any) => (
              <Accordion key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <Typography fontSize="20px" fontWeight="600">{quesAns?.key} </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography> {quesAns?.value}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Container>
        </Box>
      }
    </React.Fragment>

  );
}

export default Faq;
