export const degreeInterestOptions: any= [
    {
        "type": "Career Training",
        "value": "1"
    },
    {
        "type": "Associates Degrees",
        "value": "2"
    },
    {
        "type": "Bachelor's Degrees",
        "value": "3"
    },
    {
        "type": "Master's Degrees",
        "value": "4"
    }
]


export const mPrograms: any = [
    { "text": "General Criminal Justice", "value": "200" },
    { "text": "Corrections", "value": "201" },
    { "text": "Crime Scence Investigation", "value": "202" },
    { "text": "Criminal Justice", "value": "203" },
    { "text": "Criminology", "value": "204" },
    { "text": "Cyber Security", "value": "205" },
    { "text": "Forensic Science", "value": "206" },
    { "text": "Homeland Security", "value": "207" },
    { "text": "Law Enforcement", "value": "208" },
    { "text": "Legal & Paralegal Studies", "value": "209" },
    { "text": "Public Safety Administration", "value": "211" },
    { "text": "Security", "value": "212" }
]
