import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles'
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      lg: 1032,
      xl: 1200
    }
  },

  palette: {
    primary: {
      main: "#00AAEE",
      contrastText: "#fff"
    },
    secondary: {
      main: '#E33E7F',
      contrastText: "#fff"
    },
    success: {
      main: "#57A773",
      contrastText: "#fff"
    },
    text: {
      primary: "#2B2B2B",
      secondary: "#4E4E4E"
    },
    background: {
      default: "#F8F8F8"
    }
  },
  typography: {
    fontFamily: "Lato"
  },

});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
